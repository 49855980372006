import { LoadingButton, LoadingButtonProps } from '@mui/lab'

type Props = LoadingButtonProps & {
  isLoading?: boolean
}

export function Button({ isLoading = false, children, sx, ...rest }: Props) {
  return (
    <LoadingButton
      startIcon={<></>}
      loadingPosition="start"
      loading={isLoading}
      disabled={isLoading}
      variant="outlined"
      sx={{ display: 'flex', alignItems: 'center', gap: 1, ...sx }}
      {...rest}
    >
      {children}
    </LoadingButton>
  )
}
