import { AdminLayout } from 'layout'
import CreateEventPage from 'pages/admin/CreateEventPage'
import DashboardPage from 'pages/admin/Dashboard/DashboardPage'
import EventDetailsPage from 'pages/admin/EventDetailsPage/EventDetailsPage'
import EventsPage from 'pages/admin/EventsPage'
import ExportCustomers from 'pages/admin/ExportCustomers'
import LoginPage from 'pages/admin/LoginPage'
import { NewEvents } from 'pages/admin/NewEvents'
import { OrderDetails } from 'pages/admin/OrderDetails'
import { OrdersUnderReview } from 'pages/admin/OrdersUnderReview'
import { PagSeguro } from 'pages/admin/PagSeguro'
import PedidosSuspeitos from 'pages/admin/PedidosSuspeitos'
import { Producer } from 'pages/admin/Producer'
import { Producers } from 'pages/admin/Producers'
import SearchOrder from 'pages/admin/SearchOrder'
import SuspectedUsers from 'pages/admin/SuspectedUsers'
import { TransactionClosingReport } from 'pages/admin/TransactionClosingReport'
import { UserDetails } from 'pages/admin/UserDetails'
import { Withdrawal } from 'pages/admin/Withdrawal'
import { WithdrawalExtract } from 'pages/admin/WithdrawalExtract'
import { WithdrawalPending } from 'pages/admin/WithdrawalPending'
import { WithdrawalRequests } from 'pages/admin/WithdrawalRequests'
import React from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { NoMatch } from '../components'
import AdminProvider from '../contexts/adminContext'
import { EventClosingReportPage } from 'pages/admin/event-closing-report'
import { EventServicesPage } from 'pages/admin/event-services'
import { ListBannersPage } from 'pages/admin/banners/list-banners'
import { CreateBannerPage } from 'pages/admin/banners/create-banner'
import { UpdateBannerPage } from 'pages/admin/banners/update-banner'

const ProtectedRoute: React.FC = () => {
  const location = useLocation()
  const admin = localStorage.getItem('admin')
  const token = localStorage.getItem('admin_token')
  if (!token && !admin) {
    return <Navigate to="/admin/login" replace state={{ from: location }} />
  }
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  )
}

const AdminRouter = () => {
  return (
    <AdminProvider>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<DashboardPage />} />

          <Route path="solicitacoes-de-saque">
            <Route index element={<WithdrawalRequests />} />
            <Route path=":withdrawalId" element={<Withdrawal />} />
            <Route path="extrato" element={<WithdrawalExtract />} />
          </Route>

          <Route path="new-events">
            <Route index element={<NewEvents />} />
          </Route>

          <Route path="events">
            <Route index element={<EventsPage />} />
            <Route path="novo" element={<CreateEventPage />} />
            <Route path="unpaid" element={<WithdrawalPending />} />
            <Route path=":eventId" element={<EventDetailsPage />} />
            <Route
              path=":eventId/export-customers"
              element={<ExportCustomers />}
            />
            <Route
              path=":eventId/fechamento"
              element={<EventClosingReportPage />}
            />
            <Route path=":eventId/servicos" element={<EventServicesPage />} />
          </Route>

          <Route path="eventos">
            <Route index element={<EventsPage />} />
            <Route path="novo" element={<CreateEventPage />} />
            <Route path="unpaid" element={<WithdrawalPending />} />
            <Route path=":eventId" element={<EventDetailsPage />} />
            <Route
              path=":eventId/export-customers"
              element={<ExportCustomers />}
            />
            <Route
              path=":eventId/fechamento"
              element={<EventClosingReportPage />}
            />
            <Route path=":eventId/servicos" element={<EventServicesPage />} />
          </Route>

          <Route path="produtoras">
            <Route index element={<Producers />} />
          </Route>
          <Route path="produtoras/:producerId">
            <Route index element={<Producer />} />
          </Route>
          <Route path="reports">
            <Route index element={<TransactionClosingReport />} />
          </Route>

          <Route path="pedidos">
            <Route path="em-analise" element={<OrdersUnderReview />} />
            <Route path="suspeitos" element={<PedidosSuspeitos />} />
          </Route>

          <Route path="banners">
            <Route index element={<ListBannersPage />} />
            <Route path="cadastrar" element={<CreateBannerPage />} />
            <Route path=":bannerId" element={<UpdateBannerPage />} />
          </Route>

          <Route path="search-order" element={<SearchOrder />} />
          <Route path="usuarios-suspeitos" element={<SuspectedUsers />} />
          <Route path="order-details/:orderId" element={<OrderDetails />} />
          <Route path="usuarios/:userId" element={<UserDetails />} />
          <Route path="pagseguro">
            <Route index element={<PagSeguro />} />
          </Route>
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </AdminProvider>
  )
}

export default AdminRouter
