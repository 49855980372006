import { useSortable } from '@dnd-kit/sortable'
import { CardActions, CardContent, Link } from '@mui/material'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { constants } from 'config'
import { CSS } from '@dnd-kit/utilities'
import { Link as LinkRouterDom } from 'react-router-dom'

type Props = {
  id: number
  title: string
  image: string
}

export function Banner(props: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Card>
        <CardMedia
          sx={{ height: 115 }}
          image={constants.URL_FTP + props.image}
          title="Banner"
        />
        <CardContent>{props.title}</CardContent>
        <CardActions>
          <Link
            component={LinkRouterDom}
            to={`/admin/banners/${props.id}`}
            underline="hover"
            color="primary"
          >
            ver mais...
          </Link>
        </CardActions>
      </Card>
    </div>
  )
}
