import { z } from 'zod'

const MAX_FILE_SIZE = 5000000
const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
]
export const bannerSchema = z.object({
  id: z.number().optional(),
  title: z.string().min(1, { message: 'Por favor informe a descrição.' }),
  link: z.string().url('Por favor informe uma URL válida.'),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  visible: z.boolean(),
  image: z.union([
    z.string().min(1, { message: 'Por favor informe uma string válida.' }),
    z
      .any()
      .transform((value) => {
        if (value instanceof File) {
          return value
        }
        return null
      })
      .refine((file) => !!file, 'Por favor selecione uma imagem.')
      .refine(
        (file) => file && file.size <= MAX_FILE_SIZE,
        `O tamanho máximo é 5MB.`,
      )
      .refine(
        (file) => file && ACCEPTED_IMAGE_TYPES.includes(file.type),
        'Somente formatos .jpg, .jpeg, .png and .webp são suportados.',
      ),
  ]),
})

export type BannerSchema = z.infer<typeof bannerSchema>
