/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid, Paper } from '@mui/material'
import { PlusCircle } from '@phosphor-icons/react'
import { Loading } from 'components'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/button'
import { useHttp } from 'hooks/useHttp'
import { useEffect, useState } from 'react'
import type { IBanner } from 'types/banner'
import { Banner } from './_components/banner'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

export function ListBannersPage() {
  const [showInactiveEvents, setShowInactiveEvents] = useState(false)
  const [activeBanners, setActiveBanners] = useState<IBanner[]>([])

  const { addMessage } = useAdmin()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const { data, isLoading, execute } = useHttp<{
    actives: IBanner[]
    banners: IBanner[]
  }>({
    clientType: 'admin',
    url: '/banners',
    onSuccess(data) {
      setActiveBanners(data.actives)
    },
    onError() {
      addMessage({ type: 'ERROR', message: 'Erro ao carregar banners' })
    },
  })

  const ordinations = useHttp({
    clientType: 'admin',
    url: '/banners/ordinations',
    method: 'post',
    onError() {
      addMessage({ type: 'ERROR', message: 'Erro ao reordenar banners' })
    },
  })

  useEffect(() => {
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const onDragEnd = (e: DropResult) => {
  //   if (!e.destination) return
  //   const tempData = Array.from(activeBanners)
  //   const [sourceData] = tempData.splice(e.source.index, 1)
  //   tempData.splice(e.destination.index, 0, sourceData)
  //   setActiveBanners(tempData)
  //   ordinations.execute(tempData)
  // }

  const banners = data?.banners ?? []

  console.log(ordinations)

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = activeBanners.findIndex((item) => item.id === active.id)
      const newIndex = activeBanners.findIndex((item) => item.id === over.id)
      const tempData = arrayMove(activeBanners, oldIndex, newIndex)
      await ordinations.execute({
        banners: tempData.map((banner) => ({ id: banner.id })),
      })
      setActiveBanners(tempData)
    }
  }

  return (
    <Grid
      item
      container
      maxWidth="lg"
      component={Paper}
      spacing={2}
      pr={2}
      pb={2}
    >
      <Grid item xs={12}>
        <Breadcrumbs items={[{ title: 'Banners' }]} />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button variant="contained" href="/admin/banners/cadastrar">
          Cadastrar <PlusCircle />
        </Button>
      </Grid>
      {isLoading && <Loading />}

      <Grid item xs={12}>
        <Typography variant="h6">Banners ativos</Typography>
      </Grid>

      <Grid
        item
        xs={12}
        container
        ml={2}
        pb={2}
        sx={{
          borderWidth: 1,
          borderColor: 'ActiveBorder',
          borderStyle: 'dashed',
        }}
      >
        <Grid item xs={12}>
          <Typography variant="caption">
            * Arraste os banners para reordenar
          </Typography>
        </Grid>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={activeBanners}
            strategy={verticalListSortingStrategy}
          >
            {activeBanners.length > 0 ? (
              activeBanners.map((banner) => (
                <Grid item key={banner.id} xs={12} sm={6} md={4} pr={2}>
                  <Banner
                    id={banner.id}
                    title={banner.descricao}
                    image={banner.imagem}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1">Nenhum banner ativo</Typography>
              </Grid>
            )}
          </SortableContext>
        </DndContext>
      </Grid>

      <Grid item xs={12}>
        <Button onClick={() => setShowInactiveEvents(!showInactiveEvents)}>
          {showInactiveEvents ? 'Ocultar' : 'Mostrar'} {banners.length} últimos
          banners inativos
        </Button>
      </Grid>
      {showInactiveEvents &&
        banners.map((banner) => (
          <Grid item key={banner.id} xs={12} sm={6} md={4}>
            <Banner
              id={banner.id}
              title={banner.descricao}
              image={banner.imagem}
            />
          </Grid>
        ))}
    </Grid>
  )
}
