import { Grid, Paper } from '@mui/material'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { BannerForm } from './_components/banner-form'

export function CreateBannerPage() {
  return (
    <Grid item container maxWidth="sm" component={Paper} p={2} gap={2}>
      <Grid item xs={12}>
        <Breadcrumbs
          items={[
            { title: 'Banners', to: '/admin/banners' },
            { title: 'Novo banner' },
          ]}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        {<BannerForm />}
      </Grid>
    </Grid>
  )
}
