import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import { TableRowItem } from 'components/TableRowItem'
import { Typography } from 'components/Typography'
import { moneyFormatter } from 'utils/formatter'

import type { DigitalResumeType } from './types'
import { useEffect } from 'react'

type Props = {
  digital: DigitalResumeType
  requestedDigitalWithdrawals: number
  eventIsClosed: boolean
  setTotal?: (total: number) => void
}

export function DigitalSalesTable({
  digital,
  requestedDigitalWithdrawals,
  eventIsClosed,
  setTotal,
}: Props) {
  let amountBlockedForWithdraw = digital.amountBlockedForWithdraw
  let advancePercentage = digital.advancePercentage

  if (eventIsClosed) {
    amountBlockedForWithdraw = 0
    advancePercentage = 100
  }

  const itemA = digital.amount
  const itemB = digital.absorbedFees
  const itemC = digital.installmentInterestAbsorbed
  const itemD = digital.discountCoupons
  const itemE = digital.services.total
  const subtotal = itemA - itemB - itemC - itemD - itemE

  const itemF = amountBlockedForWithdraw
  const itemG = (subtotal * advancePercentage) / 100 - itemF
  const itemH = digital.amountWithdrawn ?? 0
  const itemI = requestedDigitalWithdrawals
  const availableAmountToWithdraw = itemG - itemH - itemI

  useEffect(() => {
    setTotal?.(availableAmountToWithdraw)
  }, [availableAmountToWithdraw, setTotal])

  return (
    <TableContainer component={Paper}>
      <Typography sx={{ flex: '1 1 100%', pt: 2, pl: 2 }} variant="h6">
        Vendas digitais (Site)
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRowItem
            title="Valor de vendas (A)"
            value={moneyFormatter.format(itemA)}
          />
          <TableRowItem
            title="Taxas absorvidas (B)"
            info="Taxas absorvidas dos lotes"
            value={moneyFormatter.format(itemB)}
          />
          <TableRowItem
            title="Juros de parcelamento absorvidos (C)"
            value={moneyFormatter.format(itemC)}
          />
          <TableRowItem
            title="Cupons de desconto (D)"
            value={moneyFormatter.format(itemD)}
          />
          <TableRowItem
            title="Outras despesas (E)"
            value={moneyFormatter.format(itemE)}
            items={digital.services.items.map(({ description, value }) => ({
              description,
              value: moneyFormatter.format(value),
            }))}
          />
          <TableRowItem
            title="Subtotal = (A - B - C - D - E)"
            value={moneyFormatter.format(subtotal)}
            bold
          />
          <TableRowItem
            title={`Valor bloqueado ${digital.daysToRetainTheWithdraw} dias (F)`}
            value={moneyFormatter.format(itemF)}
          />
          <TableRowItem
            title={`Valor disponível (G) = (Subtotal * ${advancePercentage}%) - (F)`}
            value={moneyFormatter.format(itemG)}
          />
          <TableRowItem
            title="Valor sacado (H)"
            value={moneyFormatter.format(itemH)}
          />
          <TableRowItem
            title="Valores solicitados (I)"
            value={moneyFormatter.format(itemI)}
            info="Saques solicitados ainda em análise"
          />
          <TableRowItem
            title="Valor liberado para saque = (G) - (H) - (I)"
            value={moneyFormatter.format(availableAmountToWithdraw)}
            bold
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
