import { zodResolver } from '@hookform/resolvers/zod'
import { SaveOutlined } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Button } from 'components/button'
import { useHttp } from 'hooks/useHttp'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { bannerSchema, type BannerSchema } from '../banner-schema'
import { constants } from 'config'
import { useAdmin } from 'contexts/adminContext'
import { useNavigate } from 'react-router-dom'

type BannerFormProps = {
  initialData?: BannerSchema
}

export function BannerForm({ initialData }: BannerFormProps) {
  const [imgSrc, setImgSrc] = useState('')
  const imgRef = useRef<HTMLImageElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { addMessage } = useAdmin()
  const navigate = useNavigate()

  const url = initialData ? `/banners/${initialData.id}` : '/banners'
  const method = initialData ? 'patch' : 'post'

  const http = useHttp({
    clientType: 'admin',
    url,
    method,
    headers: { 'Content-Type': 'multipart/form-data' },
    onSuccess() {
      addMessage({ type: 'SUCCESS', message: 'Banner salvo com sucesso' })
      navigate(-1)
    },
    onError() {
      addMessage({ type: 'ERROR', message: 'Erro ao salvar banner' })
    },
  })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<BannerSchema>({
    resolver: zodResolver(bannerSchema),
    shouldFocusError: true,
    defaultValues: initialData ?? {
      startDate: new Date(),
      endDate: new Date(),
      visible: true,
    },
  })

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
      setValue('image', e.target.files[0])
    }
  }

  async function onSubmit(data: BannerSchema) {
    if (isSubmitting) return

    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('link', data.link)
    formData.append('startDate', data.startDate.toISOString())
    formData.append('endDate', data.endDate.toISOString())
    formData.append('visible', data.visible.toString())

    if (data.image) {
      formData.append('image', data.image)
    }

    await http.execute(formData)
  }

  useEffect(() => {
    if (initialData?.image && typeof initialData.image === 'string') {
      setImgSrc(constants.URL_FTP + initialData.image)
    }
  }, [initialData])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Imagem"
          fullWidth
          type="file"
          inputProps={{ accept: 'image/*', ref: fileInputRef }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: imgSrc && (
              <img
                ref={imgRef}
                alt="Selecione a imagem"
                src={imgSrc}
                style={{
                  objectFit: 'cover',
                  maxWidth: '100%',
                  maxHeight: '80%',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
                onClick={() => fileInputRef.current?.click()}
              />
            ),
            sx: {
              height: '100%',
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
          sx={{ height: '100%', aspectRatio: '16/8' }}
          onChange={onSelectFile}
          error={!!errors.image}
          helperText={errors.image?.message}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Descrição"
          error={!!errors.title}
          helperText={errors.title?.message}
          fullWidth
          required
          {...register('title')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Link"
          error={!!errors.link}
          helperText={errors.link?.message}
          fullWidth
          required
          {...register('link')}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={control}
          name="startDate"
          rules={{ required: true }}
          render={({ field }) => (
            <DateTimePicker
              label="Data e hora para começar a exibir o banner"
              value={field.value}
              inputRef={field.ref}
              onChange={(date) => {
                field.onChange(date)
              }}
              slotProps={{
                textField: {
                  error: !!errors.startDate,
                  helperText: errors.startDate?.message,
                  fullWidth: true,
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={control}
          name="endDate"
          rules={{ required: true }}
          render={({ field }) => (
            <DateTimePicker
              label="Data e hora para parar de exibir o banner"
              value={field.value}
              inputRef={field.ref}
              onChange={(date) => {
                field.onChange(date)
              }}
              slotProps={{
                textField: {
                  error: !!errors.endDate,
                  helperText: errors.endDate?.message,
                  fullWidth: true,
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="visible"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!field.value} />}
              label="Visível"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          loading={http.isLoading}
          loadingPosition="start"
          startIcon={<SaveOutlined />}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          fullWidth
        >
          Salvar banner
        </Button>
      </Grid>
    </Grid>
  )
}
