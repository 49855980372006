import DragHandleIcon from '@mui/icons-material/DragHandle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Switch,
} from '@mui/material'
import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { apiV1 } from 'services'
import { IIngresso } from 'types/ingresso'
import { functionWithLoading } from 'utils'
import { moneyFormatter } from 'utils/formatter'
import BatchItemStatus from './BatchItemStatus'
import { constants } from 'config'

interface BatchItemProps {
  batch: IIngresso
  setBatch: (batchId: number) => void
  isAdmin: boolean
  callback: () => void
  index: number
}

export const BatchItem = ({
  batch,
  setBatch,
  isAdmin,
  // callback,
  index,
}: BatchItemProps) => {
  const [localBatch, setLocalBatch] = React.useState<IIngresso>(batch)
  const [loading, setLoading] = React.useState(false)

  const handleChange = React.useCallback(() => {
    if (isAdmin) {
      functionWithLoading(
        apiV1.admin.ticketService
          .toggleVisible(localBatch.cod_evento, localBatch.id)
          .then(({ data }) => setLocalBatch(data)),
        setLoading,
      )
    } else {
      functionWithLoading(
        apiV1.producer.ticketService
          .toggleVisible(localBatch.cod_evento, localBatch.id)
          .then(({ data }) => setLocalBatch(data)),
        setLoading,
      )
    }
  }, [isAdmin, localBatch.cod_evento, localBatch.id])

  return (
    <Box>
      <Divider />
      <CardHeader
        avatar={
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <Switch
                checked={Boolean(localBatch.visivel)}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            {!!localBatch.imagem && (
              <img
                alt=""
                src={constants.URL_FTP + localBatch.imagem}
                style={{
                  maxHeight: 32,
                  maxWidth: 32,
                  objectFit: 'cover',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
              />
            )}
          </>
        }
        action={
          <>
            <IconButton
              aria-label="detalhes"
              onClick={() => setBatch(localBatch.id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Draggable draggableId={'batch_' + localBatch.id} index={index}>
              {(provided) => (
                <IconButton
                  aria-label="reordenar"
                  // onClick={() => {}}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <DragHandleIcon />
                </IconButton>
              )}
            </Draggable>
          </>
        }
        title={localBatch.titulo}
        subheader={
          <>
            {moneyFormatter.format(localBatch.valor)}{' '}
            <BatchItemStatus batch={localBatch} />
          </>
        }
      />
    </Box>
  )
}
