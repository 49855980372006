/* eslint-disable camelcase */
import { Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useHttp } from 'hooks/useHttp'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import type { IPedido } from 'types/pedido'
import { datagrid } from 'utils'
import { moneyFormatter } from 'utils/formatter'

export default function PedidosSuspeitos() {
  const [eventId, setEventId] = useState<number>()
  const [searchQuery, setSearchQuery] = useState('')
  // const [contaisTransfer, setContaisTransfer] = useState()

  const { data, execute, isLoading } = useHttp<{ orders: IPedido[] }>({
    clientType: 'admin',
    url: 'orders/suspected',
  })

  const orders = data?.orders

  const filteredOrders =
    orders?.filter((order) => order.codigo.includes(searchQuery)) ?? []

  const columns: GridColDef[] = [
    {
      field: 'evento.titulo',
      headerName: 'Evento',
      flex: 1,
      renderCell: (values) => values.row.evento.titulo,
    },
    {
      field: 'codigo',
      headerName: 'Código pedido',
      width: 150,
      renderCell: (values) => {
        return (
          <Link to={`/admin/order-details/${values.row.codigo}`}>
            {values.row.codigo}
          </Link>
        )
      },
    },
    {
      field: 'data',
      headerName: 'Data compra',
      width: 150,
      renderCell: (params) => dayjs(params.value).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'valor_pedido',
      headerName: 'Valor pedido',
      width: 150,
      renderCell: (params) => moneyFormatter.format(params.value),
    },
    {
      field: 'transferido',
      headerName: 'Transferido',
      width: 100,
      renderCell(params) {
        return params.row.transferido ? 'Sim' : 'Não'
      },
    },
    {
      field: 'usuario._count.pedido',
      headerName: 'Recusas',
      width: 100,
      renderCell(params) {
        return params.row.usuario._count.pedido
      },
    },
    {
      field: 'usuario.nome',
      headerName: 'Comprador',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={`/admin/usuarios/${params.row.usuario.id}`} target="_blank">
            {[params.row.usuario.nome, params.row.usuario.sobrenome]
              .join(' ')
              .trim()
              .toUpperCase()}
          </Link>
        )
      },
    },
  ]

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={9}>
          <TextField
            label="ID evento"
            variant="outlined"
            fullWidth
            onChange={(e) => setEventId(Number(e.target.value))}
            value={eventId}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" onClick={() => execute({ eventId })}>
            Carregar
          </Button>
        </Grid>
      </Grid>

      <Box
        justifyContent="center"
        alignContent="center"
        sx={{
          p: 2,
          display: 'flex',
        }}
      >
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </Box>
      <Box sx={{ p: 2, flex: 1 }}>
        {isLoading ? (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Skeleton variant="rectangular" height={400} />
            </Grid>
          </Grid>
        ) : (
          <DataGrid
            rows={filteredOrders}
            columns={columns}
            autoHeight
            getRowId={(row) => row.codigo}
            localeText={datagrid.localizedTextsMap}
          />
        )}
      </Box>
    </Card>
  )
}
