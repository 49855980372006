import { zodResolver } from '@hookform/resolvers/zod'
import { PhotoCamera } from '@mui/icons-material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { constants } from 'config'
import dayjs from 'dayjs'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import * as React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import clientAdmin from 'services/api/v1/admin/client'
import { IEvento } from 'types/evento'
import { IGateway } from 'types/gateway'
import { IPromoter } from 'types/promoter'
import { functionWithLoading } from 'utils'
import { canvasPreview } from 'utils/canvasPreview'
import { centerAspectCrop } from 'utils/image'
import { useDebounceEffect } from 'utils/useDebounceEffect'
import { z } from 'zod'

const schema = z
  .object({
    id: z.number(),
    titulo: z.string().min(1, 'Preencha o título do evento!'),
    data: z.coerce.date({
      required_error: 'Preencha a data de início do evento!',
    }),
    data_final: z.coerce.date({
      required_error: 'Preencha a data de fim do evento!',
    }),
    data_entrar: z.coerce.date({
      required_error: 'Preencha a data de início das vendas!',
    }),
    data_encerrar_vendas: z.coerce.date({
      required_error: 'Preencha a data de fim das vendas!',
    }),
    imagem: z.string(),
    descricao: z.string(),
    local_evento: z.string().min(1, 'Preencha o local do evento!'),
    venda_suspensa: z.coerce.boolean(),
    visivel: z.coerce.boolean(),
    oculto: z.coerce.boolean(),
    acertado: z.coerce.boolean().optional(),
    pode_transferir: z.boolean().optional(),
    antecipar_saque: z.boolean().optional(),
    cancelado: z.boolean().optional(),
    cod_gateway: z.number().optional(),
    retirada: z.string().min(1, 'Preencha a observação!'),
    cod_brasil_cidade: z.coerce
      .number()
      .min(1, 'Selecione a cidade do evento!'),
    cod_promoter: z.coerce.number().min(1, 'Selecione a produtora de evento!'),
    uf: z.string().min(1, 'Selecione a UF!'),
    id_url: z.string().min(1, 'O link necessita ter pelo menos 1 caractere'),
    facebook_pixel: z.string().optional().nullable(),
    google_analytics: z.string().optional().nullable(),
    google_tag_manager: z.string().optional().nullable(),
    tiktok_pixel: z.string().optional().nullable(),
  })
  .refine((data) => data.data_final > data.data, {
    message:
      'Data de fim do evento deve ser maior que a data de início do evento.',
    path: ['data_final'],
  })
  .refine((data) => data.data_encerrar_vendas > data.data_entrar, {
    message:
      'Data de fim das vendas dever ser maior que a data de início das vendas.',
    path: ['data_encerrar_vendas'],
  })
  .refine((data) => data.data_encerrar_vendas <= data.data_final, {
    message:
      'Data de fim das vendas dever ser menor ou igual a data de fim do evento.',
    path: ['data_encerrar_vendas'],
  })

type IFormInputs = z.infer<typeof schema>

type Props = {
  eventId?: number
  isAdmin?: boolean
}

const EventForm = ({ eventId, isAdmin = false }: Props) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(),
  )
  const navigate = useNavigate()

  const [selectedUF, setSelectedUF] = React.useState<string | null>('SP')
  const [ufs, setUfs] = React.useState<string[]>([])
  const [cities, setCities] = React.useState<{ id: number; label: string }[]>(
    [],
  )
  const [loadingCity, setLoadingCity] = React.useState(true)
  const [promoters, setPromoters] = React.useState<
    { id: number; label: string }[]
  >([])
  const [gateways, setGateways] = React.useState<IGateway[]>([])

  const [imgSrc, setImgSrc] = React.useState('')
  const imgRef = React.useRef<HTMLImageElement>(null)
  const [, setCrop] = React.useState<Crop>()
  const aspect = React.useMemo(() => 16 / 9, [])
  const [selectedFile, setSelectedFile] = React.useState<File>()
  const [completedCrop] = React.useState<PixelCrop>()
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
      setSelectedFile(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width > 300 ? 300 : width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
    setError: setErrorForm,
    reset,
    resetField,
  } = useForm<IFormInputs>({
    resolver: zodResolver(schema),
    defaultValues: {
      titulo: '',
      retirada:
        'Haverá uma equipe realizando a conferência do Ingresso com QRCode na portaria do evento. Proibida a entrada de menores de 18 anos.',
      visivel: false,
      oculto: false,
      venda_suspensa: false,
      imagem: '',
      cod_brasil_cidade: 0,
      cod_promoter: 0,
      uf: 'SP',
      data: new Date(),
      data_final: new Date(),
      data_entrar: new Date(),
      data_encerrar_vendas: new Date(),

      id: 0,
      id_url: 'gerado-automático',
      descricao: '<p></p>\n',
      acertado: false,
      facebook_pixel: '',
      google_analytics: '',
      google_tag_manager: '',
      tiktok_pixel: '',
      local_evento: '',
    },
    shouldFocusError: true,
  })

  const { dirtyFields } = useFormState({ control })

  const onSubmit = async (data: IFormInputs) => {
    if (isSubmitting) {
      return
    }

    if (!eventId && !selectedFile) {
      setErrorForm('imagem', { message: 'Selecione a imagem do evento!' })
      return
    }
    if (selectedFile) {
      const formData = new FormData()
      formData.append('imagem', selectedFile)
      if (isAdmin) {
        data.imagem = (
          await apiV1.admin.uploadService.eventImage(formData)
        ).data
      } else {
        data.imagem = (
          await apiV1.producer.uploadService.eventImage(formData)
        ).data
      }
    }

    const description = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    )
    if (!description || description === '<p></p>\n') {
      setErrorForm('descricao', { message: 'Preencha a descrição do evento!' })
      return
    }
    data.descricao = description

    if (eventId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const update = {} as unknown as any
      Object.keys(dirtyFields).map((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        update[key] = (data as any)[key]
        return key
      })
      if (selectedFile) {
        update.imagem = data.imagem
      }

      const editorDescription = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      )
      if (watch('descricao') !== editorDescription)
        update.descricao = editorDescription

      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.eventService
            .update(Number(eventId), update)
            .then(() => {
              setSuccess('Evento atualizado!')
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.eventService
            .update(Number(eventId), update)
            .then(() => {
              setSuccess('Evento atualizado!')
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      }
    } else {
      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.eventService
            .create(data as unknown as IEvento)
            .then(({ data }) => {
              setSuccess('Evento cadastrado!')
              navigate('/admin/events/' + data.id)
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.eventService
            .create(data as unknown as IEvento)
            .then(({ data }) => {
              setSuccess('Evento cadastrado!')
              navigate('/organizador/eventos/' + data.id)
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      }
    }
  }

  React.useEffect(() => {
    apiV1.cityService.getUFs().then(({ data }) => {
      setUfs(data.map((x) => x.uf))
    })
    if (isAdmin) {
      apiV1.admin.promoterService.getList().then(({ data }) =>
        setPromoters(
          data.promoters.map((x: IPromoter) => ({
            id: x.id,
            label: `#${x.id} - ${x.razao_social} - [${x.usuario.nome} ${x.usuario.sobrenome}]`,
          })),
        ),
      )
      clientAdmin.get('gateways').then(({ data }) => setGateways(data.gateways))
    } else {
      apiV1.producer.promoterService.getAll().then(({ data }) =>
        setPromoters(
          data.map((x) => ({
            id: x.id,
            label: x.razao_social,
          })),
        ),
      )
    }
    if (eventId) {
      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.eventService
            .get(eventId)
            .then(({ data }) => {
              reset({ ...data, uf: data.brasil_cidade?.uf })
              if (data.brasil_cidade?.uf) {
                setSelectedUF(data.brasil_cidade.uf)
              }
              const contentBlock = htmlToDraft(data.descricao)
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                  contentBlock.contentBlocks,
                )
                const _editorState = EditorState.createWithContent(contentState)
                setEditorState(_editorState)
              }
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.eventService
            .get(eventId)
            .then(({ data }) => {
              reset({ ...data, uf: data.brasil_cidade?.uf })
              if (data.brasil_cidade?.uf) {
                setSelectedUF(data.brasil_cidade.uf)
              }
              const contentBlock = htmlToDraft(data.descricao)
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                  contentBlock.contentBlocks,
                )
                const _editorState = EditorState.createWithContent(contentState)
                setEditorState(_editorState)
              }
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      }
    }
  }, [eventId, isAdmin, reset, resetField])

  React.useEffect(() => {
    if (selectedUF)
      functionWithLoading(
        apiV1.cityService.getCities(selectedUF).then(({ data }) => {
          setCities(
            data.map((x) => ({
              id: x.id,
              label: x.cidade,
            })),
          )
          resetField('cod_brasil_cidade')
        }),
        setLoadingCity,
      )
  }, [resetField, selectedUF, setValue])

  if (loading)
    return (
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    )

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container component={Paper} spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {eventId ? 'Atualizar' : 'Novo'} evento
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Título"
            error={!!errors.titulo}
            helperText={errors.titulo?.message}
            {...register('titulo')}
            fullWidth
            required
            InputLabelProps={{ shrink: eventId ? true : undefined }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Início do evento"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data,
                    helperText: errors.data?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data_final"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Fim do evento"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_final,
                    helperText: errors.data_final?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data_entrar"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Início das vendas"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_entrar,
                    helperText: errors.data_entrar?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data_encerrar_vendas"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Fim das vendas"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_encerrar_vendas,
                    helperText: errors.data_encerrar_vendas?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            label="Local do evento"
            error={!!errors.local_evento}
            helperText={errors.local_evento?.message}
            {...register('local_evento')}
            fullWidth
            required
            InputLabelProps={{ shrink: eventId ? true : undefined }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="uf"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <InputLabel id="uf-select-label">UF</InputLabel>
                <Select
                  labelId="uf-select-label"
                  id="uf-select"
                  value={value}
                  label="UF"
                  onChange={(event) => {
                    onChange(event)
                    setSelectedUF(event.target.value)
                  }}
                >
                  {ufs.map((uf) => (
                    <MenuItem key={uf} value={uf}>
                      {uf}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="cod_brasil_cidade"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={cities}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onChange={onChange}
                      label="Cidade"
                      error={!!errors.cod_brasil_cidade}
                      helperText={errors.cod_brasil_cidade?.message}
                      fullWidth
                      required
                      disabled={!selectedUF}
                    />
                  )
                }}
                onChange={(event, values) => onChange(values?.id)}
                getOptionLabel={(item) => item.label}
                value={cities.find((x) => x.id === value) || null}
                loading={loadingCity}
                loadingText="Carregando..."
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="cod_promoter"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={promoters}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onChange={onChange}
                      label="Produtora"
                      error={!!errors.cod_promoter}
                      helperText={errors.cod_promoter?.message}
                      fullWidth
                      required
                    />
                  )
                }}
                onChange={(event, values) => onChange(values?.id)}
                getOptionLabel={(item) => item.label}
                value={promoters.find((x) => x.id === value) || null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography variant="h6">Integrações com tráfego digital</Typography>
          <Typography variant="subtitle1">
            Informar somente o identificador da Tag/Pixel, caso tenha dúvidas
            entrar em contato com o suporte.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <TextField
            variant="outlined"
            label="Pixel Facebook"
            error={!!errors.facebook_pixel}
            helperText={errors.facebook_pixel?.message}
            {...register('facebook_pixel')}
            fullWidth
            InputLabelProps={{ shrink: !!eventId }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <TextField
            variant="outlined"
            label="Google Analytics"
            error={!!errors.google_analytics}
            helperText={errors.google_analytics?.message}
            {...register('google_analytics')}
            fullWidth
            InputLabelProps={{ shrink: !!eventId }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <TextField
            variant="outlined"
            label="Google Tag Manager"
            error={!!errors.google_tag_manager}
            helperText={errors.google_tag_manager?.message}
            {...register('google_tag_manager')}
            fullWidth
            InputLabelProps={{ shrink: !!eventId }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <TextField
            variant="outlined"
            label="Pixel TikTok"
            error={!!errors.tiktok_pixel}
            helperText={errors.tiktok_pixel?.message}
            {...register('tiktok_pixel')}
            fullWidth
            InputLabelProps={{ shrink: !!eventId }}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        {!!eventId && (
          <>
            <Grid container item xs={12} sm={6}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="URL"
                  error={!!errors.id_url}
                  helperText={errors.id_url?.message}
                  {...register('id_url')}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  InputLabelProps={{ shrink: !!eventId }}
                />
              </Grid>
              <Grid item xs={12}>
                <Link
                  href={`https://zedoingresso.com.br/evento/${eventId}/${watch(
                    'id_url',
                  )}`}
                  target="_blank"
                >
                  https://zedoingresso.com.br/evento/{eventId}/{watch('id_url')}
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <canvas id="qrcode-evento" />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            label="Imagem do evento"
            contentEditable={false}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <Grid container spacing={2} mt={1} pr={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      component="label"
                      endIcon={<PhotoCamera />}
                      fullWidth
                    >
                      Selecionar imagem
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('imagem')}
                        onChange={onSelectFile}
                        required
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="caption"
                      component="p"
                      textAlign="center"
                    >
                      A imagem deve estar nas dimensões 1024px x 600px para se
                      adaptar no layout da plataforma.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                      > */}
                    <img
                      ref={imgRef}
                      alt="Selecione a imagem"
                      src={imgSrc || constants.URL_FTP + watch('imagem')}
                      style={{ width: '100%' }}
                      onLoad={onImageLoad}
                    />
                    {/* </ReactCrop> */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* {Boolean(completedCrop) && (
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "contain",
                          width: completedCrop?.width,
                          height: completedCrop?.height,
                        }}
                      />
                    )} */}
                  </Grid>
                </Grid>
              ),
              inputProps: {
                style: { display: 'none' },
              },
            }}
            error={!!errors.imagem}
            helperText={errors.imagem?.message}
            required
            fullWidth
          />
        </Grid>
        {editorState && (
          <Grid item xs={12}>
            <TextField
              label="Descrição do evento"
              contentEditable={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    editorStyle={{
                      minHeight: 100,
                      maxHeight: 500,
                      overflow: 'scroll',
                    }}
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'textAlign',
                        'list',
                        'colorPicker',
                        'link',
                        'embedded',
                        'emoji',
                        'image',
                        'history',
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                        ],
                      },
                      fontFamily: {
                        isDropdown: true,
                      },
                      textAlign: {
                        options: ['left', 'center', 'right', 'justify'],
                      },
                    }}
                    toolbarStyle={{ marginLeft: -14 }}
                  />
                ),
                inputProps: {
                  style: { display: 'none' },
                },
              }}
              error={!!errors.descricao}
              helperText={errors.descricao?.message}
              required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label="Observação"
            multiline
            rows={4}
            error={!!errors.retirada}
            helperText={errors.retirada?.message}
            fullWidth
            {...register('retirada')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="legend">Validações</FormLabel>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
              {isAdmin && (
                <Controller
                  name="visivel"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={!!field.value} />}
                      label="Visível (acessível)"
                    />
                  )}
                />
              )}
              <Controller
                name="oculto"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Ocultar evento"
                  />
                )}
              />
              {!!eventId && (
                <Controller
                  name="venda_suspensa"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={!!field.value} />}
                      label="Suspender vendas"
                    />
                  )}
                />
              )}
              {isAdmin && (
                <Controller
                  name="acertado"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={!!field.value} />}
                      label="Acertado"
                    />
                  )}
                />
              )}

              <Controller
                name="pode_transferir"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Pode transferir ingressos"
                  />
                )}
              />

              {isAdmin && (
                <Controller
                  name="cancelado"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={!!field.value} />}
                      label="Cancelado"
                    />
                  )}
                />
              )}
              {isAdmin && (
                <Controller
                  name="antecipar_saque"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={!!field.value} />}
                      label="Antecipar saque"
                    />
                  )}
                />
              )}
              {isAdmin && (
                <Controller
                  control={control}
                  name="cod_gateway"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="gateway-select-label">
                        Gateway de pagamento
                      </InputLabel>
                      <Select
                        labelId="gateway-select-label"
                        id="gateway-select"
                        value={value}
                        label="Gateway de pagamento"
                        onChange={onChange}
                      >
                        {gateways.map((gateway) => (
                          <MenuItem key={gateway.id} value={gateway.id}>
                            {gateway.titulo}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              )}
            </FormGroup>
          </FormControl>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          </Grid>
        )}
        {success && (
          <Grid item xs={12}>
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveOutlinedIcon />}
            variant="contained"
            type="submit"
            sx={{ mt: 3, mb: 2 }}
          >
            {eventId ? 'Atualizar' : 'Cadastrar'} evento
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(EventForm)
