import { Grid, Paper, Skeleton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { EventMarkAsPayButton } from './components/EventMarkAsPayButton'

type EventResponse = {
  id: number
  title: string
  date: string
  producer: string

  totalBilled: number
  totalTickets: number
  totalProducerFee: number
  totalGatewayFee: number
  totalSystemFee: number
  totalInstallmentFee: number
  totalServices: number
  totalDiscountCoupons: number
  totalTransferred: number
  totalLosses: number
  netTotal: number
  amountPaid: number
  balance: number

  warnings: string[]
}

type Response = {
  events: EventResponse[]
  limitDate: string
}

export function WithdrawalPending() {
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState<Response>({
    events: [] as EventResponse[],
  } as Response)

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await clientAdmin.get('/events/unpaid')
      setReport(data)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <PageLoading />

  const columns: GridColDef<EventResponse>[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      renderCell: (cell) => (
        <Link to={`/admin/events/${cell.value}`} target="_blank">
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'title',
      headerName: 'Evento',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Data evento',
      width: 150,
      renderCell: (cell) => dayjs(cell.value).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'producer',
      headerName: 'Produtora',
      width: 300,
    },
    {
      field: 'totalTransferred',
      headerName: 'Repasse',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'amountPaid',
      headerName: 'Valor já pago',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'balance',
      headerName: 'Valor à pagar',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'action',
      headerName: 'Ações',
      type: 'number',
      width: 150,
      renderCell: (cell) =>
        dayjs().isAfter(dayjs(cell.row.date)) && (
          <EventMarkAsPayButton eventId={cell.row.id} />
        ),
    },
  ]

  const totalPastEvents = report.events.reduce((total, event) => {
    if (dayjs().isAfter(event.date)) {
      total =
        total + (Number(event.totalTransferred) - Number(event.amountPaid))
    }
    return total
  }, 0)

  const totalFutureEvents = report.events.reduce((total, event) => {
    if (dayjs().isBefore(event.date)) {
      total =
        total + (Number(event.totalTransferred) - Number(event.amountPaid))
    }
    return total
  }, 0)

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Typography variant="h5">Relatório de eventos em aberto</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Valores à pagar de eventos já encerrados:{' '}
          {moneyFormatter.format(totalPastEvents)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Valores à pagar de eventos futuros:{' '}
          {moneyFormatter.format(totalFutureEvents)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Valores à pagar:{' '}
          {moneyFormatter.format(totalPastEvents + totalFutureEvents)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid rows={report.events} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
