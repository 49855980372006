import { Grid, Paper } from '@mui/material'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { BannerForm } from './_components/banner-form'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Loading } from 'components'
import { useHttp } from 'hooks/useHttp'
import type { IBanner } from 'types/banner'
import { bannerSchema, type BannerSchema } from './banner-schema'

export function UpdateBannerPage() {
  const { bannerId } = useParams<{ bannerId: string }>()

  const { data, isLoading, execute } = useHttp<{ banner: IBanner }>({
    clientType: 'admin',
    url: `/banners/${bannerId}`,
  })

  useEffect(() => {
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const banner = data
    ? bannerSchema.safeParse({
        id: data?.banner.id,
        title: data?.banner.descricao,
        link: data?.banner.link,
        startDate: new Date(data?.banner.entrar_em),
        endDate: new Date(data?.banner.data_sair),
        visible: Boolean(data?.banner.visivel),
        image: data?.banner.imagem,
      })
    : { success: false, data: {} as BannerSchema }

  return (
    <Grid item container maxWidth="sm" component={Paper} p={2} gap={2}>
      <Grid item xs={12}>
        <Breadcrumbs
          items={[
            { title: 'Banners', to: '/admin/banners' },
            { title: 'Atualizar banner' },
          ]}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        {isLoading && <Loading />}
        {banner.success && <BannerForm initialData={banner.data} />}
      </Grid>
    </Grid>
  )
}
